<template>
  <div class="row m-0">
    <img
      :src="isMobile ? require('@/assets/img/banner-hp-mcafee-mobile.svg') : require('@/assets/img/banner-hp-mcafee-web.svg')"
      alt="Segurança digital com a McAfee"
      class="img-fluid col-12 row styleImg"
    />
  </div>

  <div class="container p-4 p-md-5">
    <p class="m-0 text-center mb-4 h4 text-dark">Fique do lado seguro:</p>

    <ProductsCatalog :products="productsComputed" :add-product="addProduct" :open-cart="openCartAction" />

    <Platforms htmlClass="mt-5 text-center" />

    <Brands />

    <p class="m-0 text-center small text-dark">* O preço mostrado corresponde ao primeiro ano. Consulte abaixo os detalhes da oferta.</p>
  </div>

  <CheckList />

  <div class="mt-3 container">
    <Carousel />
  </div>

  <AntivirusProtectionFeature />

  <div class="mt-4">
    <CustomerTrust />
  </div>

  <WhyMcAfee />

  <DeviceProtection />

  <Services />

  <div class="container mt-4 p-md-5 w-100 mb-5 d-flex flex-column align-items-center">
    <p class="h3 text-dark fw-normal text-center mb-4">Ative sua proteção <b class="fw-bold text-danger">hoje</b></p>

    <p class="text-dark text-center">Ao ativar sua assinatura adicionaremos os dias restantes da sua avaliação a ela, assim você não perderá nenhum momento da proteção grátis.</p>

    <ProductsCatalog :products="productsComputed" :add-product="addProduct" :open-cart="openCartAction" />
  </div>

  <Faq />
</template>

<script>
  import Carousel from "@/components/Carousel/Carousel.vue";
  import ProductsCatalog from "@/components/Sale/ProductsCatalog";
  import Configuration from "@/services/configuration"
  import productTypesOptionsConstants from "@/constants/sale/productTypesOptions.constants";
  import { mapActions, mapGetters } from "vuex";

  import Faq from "@/modules/landingPages/mcafee/components/Faq";
  import Brands from "@/modules/landingPages/mcafee/components/Brands";
  import Services from "@/modules/landingPages/mcafee/components/Services";
  import Platforms from "@/modules/landingPages/mcafee/components/Platforms";
  import WhyMcAfee from "@/components/WhyMcAfee";
  import CheckList from "@/modules/landingPages/mcafee/components/CheckList";
  import CustomerTrust from "@/modules/landingPages/mcafee/components/CustomerTrust";
  import DeviceProtection from "@/modules/landingPages/mcafee/components/DeviceProtection";
  import AntivirusProtectionFeature from "@/modules/landingPages/mcafee/components/AntivirusProtectionFeature";

  export default {
    name: "HomepageHPmcafee",
    components: {
      Carousel,
      ProductsCatalog,
      Faq,
      Platforms,
      Services,
      DeviceProtection,
      WhyMcAfee,
      CustomerTrust,
      AntivirusProtectionFeature,
      CheckList,
      Brands
    },
    props: {
      title: String
    },
    data() {
      return {
        isMobile: false,
        mobileWidth: 475,
      };
    },
    computed: {
      ...mapGetters({ productsComputed: 'saleFlow/product/getProducts' }),
    },
    mounted() {
      this.noClaroFields();
      this.getProducts();
      window.addEventListener('resize', this.handleResize);
      this.handleResize();
    },
    methods: {
      ...mapActions({
        getAllProductsCatalogAction: 'saleFlow/getAllProductsCatalog',
        setAvailableProductTypeAction: 'saleFlow/product/setAvailableProductType',
        addProductCartAction: 'cart/addProductCart',
        openCartAction: 'cart/openCart',
        setNoClaroFieldsAction: 'address/setNoClaroFields'
      }),
      getProducts() {
        this.setAvailableProductTypeAction([
          Configuration.productTypeOption[productTypesOptionsConstants.MCAFEE_DIGITAL_PROTECTION]
        ])
        .then(() => this.getAllProductsCatalogAction())
      },
      addProduct({ product, promotion }) {
        this.addProductCartAction({ product, promotion })
      },
      openCart() {
        this.openCartAction()
      },
      noClaroFields() {
        this.setNoClaroFieldsAction();
      },
      handleResize() {
        this.isMobile = window.innerWidth <= this.mobileWidth;
      }
    },
    beforeUnmount() {
      window.removeEventListener('resize', this.handleResize);
    }
  }
</script>
